import pick from 'lodash.pick';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { triggerLogout } from '../redux/reducers/identity.reducer.js';
import { SidePanel, UserEditProcessing, UserEditFailure } from '../components/index.js';
import MarketingPreferencesConsentForm from '../components/MarketingPreferencesConsentForm/MarketingPreferencesConsentForm.js';
import withFormHandlers from '../decorators/withFormHandlers.js';
import withLocalizedContent from '../language/withLocalizedContent.js';
import actions from '../redux/actions/index.js';
import UserAgreement from '../components/UserAgreement/UserAgreement.js';
import UserRoles from '../globals/userRoles.js';
import ActionList, { Action } from '../components/ActionList/ActionList.js';
import InitialConsentFormBody from '../components/InitialConsentFormBody/InitialConsentFormBody.js';
import MissingFieldsFormBody from '../components/MissingFieldsFormBody/MissingFieldsFormBody.js';
import styles from '../routes/MyProfile/MyProfile.scss';
import { epsPlatformOlbOffline, epsPlatformEltHub } from '../utils/platform.js';
import { prepareTargetUrl } from '../utils/url.js';
import { setMarketPref } from '../redux/reducers/registration/registration.reducer.js';

const IDENTITY_FORM = 'IDENTITY_FORM';
class MyProfileInitialEditPanel extends Component {
  static _getHiddenInputs = () => [
    'userName',
    'email',
    'oldPassword',
    'password',
    'newPassword',
    'confirmPassword',
    'roleName',
    'yearGroup'
  ];

  static _offlineAppRedirect = () => {
    const offlineAppInitialState = sessionStorage.getItem('offlineAppInitialState');

    if (offlineAppInitialState) {
      window.location.href = `/offlineApp/AUTH_SUCCES`;
    }
  };

  constructor() {
    super();
    this.state = {
      form: IDENTITY_FORM,
      shouldConsent: true,
      ageSelectedOption: '',
      firstName: '',
      lastName: '',
      underAgeAccepted: false,
      tsAndCsAccepted: false,
      privacyPolicyAccepted: false,
      profileInitialConsent: true
    };
  }

  componentWillMount() {
    const { isOpen } = this.props;
    if (isOpen) {
      this._getUserDetails();
    }
  }

  componentDidUpdate(prevProps) {
    const { isSuccessful, isOpen } = this.props;
    if (!prevProps.isSuccessful && isSuccessful) {
      MyProfileInitialEditPanel._offlineAppRedirect();
      return;
    }

    if (!prevProps.isOpen && isOpen) {
      this._getUserDetails();
    }
  }

  static onExtraDetailsClose = () => {
    const redirectTo = prepareTargetUrl();

    if (redirectTo) {
      window.location.href = redirectTo;
    }
  };

  _setTermsAndConditions = value => {
    this.setState({ tsAndCsAccepted: value });
    this.setState({ privacyPolicyAccepted: value });
  };

  _setUnderAgeAccepted = value => {
    this.setState({ underAgeAccepted: value });
  };

  _setMarketPreference = value => {
    const { setMarketPrefAction } = this.props;

    setMarketPrefAction(value);
  };

  _getUserDetails = () => {
    const { getUserDetails } = this.props;
    getUserDetails();
  };

  _resetPanel = () => {
    const { onClearFormChanges, reset } = this.props;
    onClearFormChanges();
    reset();
  };

  _closePanel = () => {
    const { closePanel } = this.props;
    this._resetPanel();
    closePanel();
  };

  _editIdentity = () => {
    const {
      formInputChanges,
      editIdentity,
      platform,
      userId,
      acceptOlbTerms,
      ebookSampleMissingFields,
      marketingEmailAcceptedValue
    } = this.props;
    const { tsAndCsAccepted } = this.state;
    const identityFormInputs = ['firstName', 'lastName'];
    const missingFieldFormInputs = ['selfSelectedRole', 'claimedSchool', 'countryCode'];
    const stateInputs = ['privacyPolicyAccepted', 'underAgeAccepted', 'tsAndCsAccepted'];
    const sendingObject = ebookSampleMissingFields
      ? {
          ...pick(this.props, identityFormInputs),
          ...pick(this.props, missingFieldFormInputs),
          ...pick(formInputChanges, missingFieldFormInputs)
        }
      : {
          ...pick(this.props, identityFormInputs),
          ...pick(formInputChanges, identityFormInputs),
          ...pick(this.state, stateInputs),
          marketingEmailAccepted: marketingEmailAcceptedValue
        };
    if (!ebookSampleMissingFields) {
      switch (platform) {
        case epsPlatformOlbOffline:
          acceptOlbTerms(userId);
          break;
        case epsPlatformEltHub:
          sendingObject.hubTsAndCsAccepted = tsAndCsAccepted;
          break;
        default:
          sendingObject.tsAndCsAccepted = tsAndCsAccepted;
      }
    }
    editIdentity(sendingObject);
  };

  _isEditIdentityActionDisabled = () => {
    const {
      ebookSampleMissingFields,
      formInputErrors: { firstName, lastName, selfSelectedRole = true, claimedSchool = true, countryCode = true }
    } = this.props;
    const { tsAndCsAccepted } = this.state;
    return ebookSampleMissingFields
      ? selfSelectedRole || claimedSchool || countryCode
      : !tsAndCsAccepted || firstName || lastName;
  };

  _renderContent = () => {
    const {
      localizedContent: { myProfileInitialEditPanel: content },
      formInputChanges,
      email,
      userName,
      platform,
      isLoading,
      isSuccessful,
      hasFailed,
      ebookSampleMissingFields
    } = this.props;

    switch (true) {
      case hasFailed:
        return <UserEditFailure onDone={this._closePanel} />;
      case isLoading || isSuccessful:
        return <UserEditProcessing isLoading />;
      default:
        return (
          <div className={styles.consentModalBody}>
            <header className={styles.consentHeader}>
              <h2>
                {ebookSampleMissingFields
                  ? content.ebook_sample_missing_field_information
                  : content.my_profile_initial_consent_information}
              </h2>
              {ebookSampleMissingFields && <p>{content.ebook_sample_missing_field_name_header}</p>}
            </header>

            <div className={styles.consentMainBody}>
              {ebookSampleMissingFields ? (
                <MissingFieldsFormBody
                  {...pick(this.props, [
                    'onChange',
                    'onBlur',
                    'ebookSampleMissingFields',
                    'claimedSchool',
                    'countryCode',
                    'selfSelectedRole',
                    'formInputErrors'
                  ])}
                  {...formInputChanges}
                  localizedContent={content}
                  origUserName={userName}
                />
              ) : (
                <InitialConsentFormBody
                  {...pick(this.props, [
                    'firstName',
                    'lastName',
                    'userName',
                    'email',
                    'oldPassword',
                    'newPassword',
                    'confirmPassword',
                    'formInputErrors',
                    'isCheckingUserNameTaken',
                    'isUserNameTaken',
                    'isCheckingEmailTaken',
                    'isEmailTaken',
                    'onChange',
                    'onBlur'
                  ])}
                  {...formInputChanges}
                  localizedContent={content}
                  origUserName={userName}
                  origEmail={email}
                  hidden={MyProfileInitialEditPanel._getHiddenInputs()}
                />
              )}
              {!ebookSampleMissingFields && (
                <div className={styles.marketingPrefContainer}>
                  <MarketingPreferencesConsentForm
                    platform={platform}
                    setMarketPreference={value => {
                      this._setMarketPreference(value);
                    }}
                    setUnderAgeAccepted={value => {
                      this._setUnderAgeAccepted(value);
                    }}
                    setMarketTermsAndConditions={value => {
                      this._setTermsAndConditions(value);
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <ActionList>
                {!ebookSampleMissingFields && <UserAgreement personalInformation />}
                <input type="hidden" name="platformCode" value={platform} />
                <Action
                  label={content.my_profile_initial_consent_continue}
                  primary
                  disabled={this._isEditIdentityActionDisabled()}
                  onClick={this._editIdentity}
                />
              </ActionList>
            </div>
          </div>
        );
    }
  };

  render() {
    const { onLogout, isSuccessfulExtraDetails } = this.props;
    switch (true) {
      case isSuccessfulExtraDetails:
        MyProfileInitialEditPanel.onExtraDetailsClose();
        return (
          <SidePanel
            id="MyProfileInitialEditPanel"
            ariaLabel=""
            className="sidePanelInitialConsent"
            isOpen
            onClose={onLogout}
          >
            {this._renderContent()}
          </SidePanel>
        );
      default:
        return (
          <SidePanel
            id="MyProfileInitialEditPanel"
            ariaLabel=""
            className="sidePanelInitialConsent"
            isOpen
            onClose={onLogout}
          >
            {this._renderContent()}
          </SidePanel>
        );
    }
  }
}

MyProfileInitialEditPanel.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  orgId: PropTypes.string,
  userId: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userName: PropTypes.string,
  identity: PropTypes.string,
  onLogout: PropTypes.func,
  formInputChanges: PropTypes.object,
  formInputErrors: PropTypes.object,
  isLoading: PropTypes.bool,
  isCheckingUserNameTaken: PropTypes.bool,
  isUserNameTaken: PropTypes.bool,
  isCheckingEmailTaken: PropTypes.bool,
  isEmailTaken: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isSuccessful: PropTypes.bool,
  hasFailed: PropTypes.bool,
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onBlurWithParams: PropTypes.func,
  onClearFormChanges: PropTypes.func,
  getUserDetails: PropTypes.func,
  acceptOlbTerms: PropTypes.func,
  validate: PropTypes.func,
  editIdentity: PropTypes.func,
  editAuth: PropTypes.func,
  reset: PropTypes.func,
  closePanel: PropTypes.func,
  isOupRole: PropTypes.bool,
  platform: PropTypes.string,
  isSuccessfulExtraDetails: PropTypes.bool,
  setMarketPrefAction: PropTypes.func,
  ebookSampleMissingFields: PropTypes.bool,
  marketingEmailAcceptedValue: PropTypes.bool
};

export default compose(
  withLocalizedContent('myProfileInitialEditPanel'),
  connect(
    ({ identity, editUser, registration }) => ({
      ...pick(editUser, [
        'firstName',
        'lastName',
        'userName',
        'email',
        'identity',
        'isLoading',
        'isCheckingUserNameTaken',
        'isUserNameTaken',
        'isCheckingEmailTaken',
        'isEmailTaken',
        'isSubmitting',
        'isSuccessful',
        'hasFailed',
        'isSuccessfulExtraDetails',
        'claimedSchool',
        'countryCode',
        'selfSelectedRole'
      ]),
      userId: identity.userId,
      orgId: identity.currentOrganisationId,
      formInputErrors: editUser.errors,
      identity,
      editUser,
      isOupRole: [UserRoles.OUP_ADMIN, UserRoles.OUP_SUPPORT].includes(identity.role),
      marketingEmailAcceptedValue: registration.marketingEmailAcceptedValue
    }),
    {
      getUserDetails: actions.editUserGetDetailsRequest,
      validate: actions.editUserValidateInitial,
      editIdentity: actions.editUserIdentityRequest,
      acceptOlbTerms: actions.acceptOlbTerms,
      editAuth: actions.editUserAuthRequest,
      reset: actions.editUserReset,
      editUserFailure: actions.editUserFailure,
      onLogout: triggerLogout,
      acceptOlbTermsSucces: actions.acceptOlbTermsSuccess,
      setMarketPrefAction: setMarketPref
    }
  ),
  withFormHandlers()
)(MyProfileInitialEditPanel);
