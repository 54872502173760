import * as t from '../actionTypes';

export const storeLineItemsRequest = (confirmedProducts, lineItemsConfig, selectedProducts, callback) => ({
  type: t.NGI_STORE_LINE_ITEMS_REQUEST,
  payload: { confirmedProducts, lineItemsConfig, selectedProducts },
  callback
});

export const storeLineItemsSuccess = payload => ({
  type: t.NGI_STORE_LINE_ITEMS_SUCCESS,
  payload
});

export const storeLineItemsFailure = error => ({
  type: t.NGI_STORE_LINE_ITEMS_FAILURE,
  payload: error
});
