import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import TextInput from '@oup/shared-front-end/src/components/TextInput/TextInput.js';
import TextLink from '@oup/shared-front-end/src/components/TextLink';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';

import PanelHeading from '../../../../../components/PanelHeading/PanelHeading.js';
import ScrollContainer from '../../../../../components/ScrollContainer/ScrollContainer.js';
import PopoutNavFooter from '../../../../../components/PopoutNavFooter/PopoutNavFooter.js';
import SVGIcon, { GLYPHS } from '../../../../../components/SVGIcon/SVGIcon';

import Validation from '../../../../../components/Validation/Validation.js';
import { isHubMode } from '../../../../../utils/platform';
import { getLabelMessage, getMessageAttributes } from '../../../../RedeemCode/getErrorMessage';

import {
  submitForm,
  setAccessCode,
  setVstActivationCode,
  validateAccessCode,
  validateVstActivationCode
} from '../../../../../redux/reducers/addToProfile.reducer.js';
import { reCAPTCHASetToken } from '../../../../../redux/actions/reCAPTCHA.js';

import cmsContent from '../../../../../utils/cmsContent.js';
import Button, { buttonTypes } from '../../../../../components/Button/Button';
import styles from './AddToProfile.scss';
import { featureIsEnabled } from '../../../../../globals/envSettings.js';

const getLicenceStatus = (errorMessageData, getLocalizedErrorMessage) => {
  let errorMessage = '';
  if (errorMessageData) {
    errorMessage = getLocalizedErrorMessage(errorMessageData);
  }
  return errorMessage;
};

const getClassNameForValidation = (redeemCodeRole, redeemCodeValueIsValid, errorMessageText, messageAttributes) => {
  if (!redeemCodeRole || !redeemCodeValueIsValid) {
    return '';
  }
  if (errorMessageText !== messageAttributes.incorrectErrorMessage) {
    return 'vstRoleMessage';
  }
  return 'errorMessage';
};

function AddToProfileEditInputting({
  closePanel,
  submitFormAction,
  redeemCodeValue,
  redeemCodeValueIsValid,
  redeemCodeValueIsError,
  errorMessage,
  isVstCode,
  validateVstRedeemCodeAction,
  setVstRedeemCodeAction,
  redeemCodeRole,
  role,
  setReCAPTCHATokenAction,
  googleReCaptchaProps
}) {
  useEffect(async () => {
    const token = await googleReCaptchaProps.executeRecaptcha(APP_CONSTANTS.RECAPTCHA_ACTIONS.REDEEM_CODE);
    setReCAPTCHATokenAction(token);
  }, [googleReCaptchaProps]);

  const joiningAndProductCodesContent =
    featureIsEnabled('opt-main-features') && isHubMode() ? cmsContent.joiningAndProductCodes : {};
  const CMS = { ...cmsContent.addToProfile, ...joiningAndProductCodesContent } || {};

  const messageAttributes = getMessageAttributes(
    redeemCodeValue,
    redeemCodeValueIsError,
    CMS.invalid_access_code_format_error,
    isVstCode ? CMS.code_with_role_max_length : CMS.code_max_length,
    getLicenceStatus(errorMessage, CMS),
    CMS.incorrect_format,
    CMS.access_code_for_teachers,
    CMS.access_code_for_students,
    CMS.access_code_mismatch_students,
    CMS.access_code_mismatch_teachers
  );
  const messageText = getLabelMessage(messageAttributes, role, redeemCodeRole, true);

  const hubHelpLink = APP_CONSTANTS[`${`${role}_HUB_REDEEM_CODE_HELP_LINK`}`]
    ? APP_CONSTANTS[`${`${role}_HUB_REDEEM_CODE_HELP_LINK`}`]
    : APP_CONSTANTS.HUB_REDEEM_CODE_HELP_LINK;

  const handleClosePanel = () => {
    setVstRedeemCodeAction('', role);
    closePanel();
  };

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <ScrollContainer
        headerContent={
          <div className={styles.header}>
            <div className={styles.close}>
              <Button text={CMS.close_panel_text} onClick={handleClosePanel} type={buttonTypes.CLOSE_BOLD} />
            </div>
            <PanelHeading title={CMS.enter_a_code_text} subtitle={CMS.redeem_access_codes_subtitle_text} />
          </div>
        }
        footerContent={
          <PopoutNavFooter
            nextButtonText={CMS.primary_button_text}
            nextAction={submitFormAction}
            nextButtonDisabled={!redeemCodeValueIsValid || redeemCodeValueIsError}
            backAction={handleClosePanel}
            backButtonText={CMS.button_cancel_text}
          />
        }
      >
        <div style={{ padding: '1rem' }}>
          <Validation
            forId="enterAccessCode"
            isValid={redeemCodeValueIsValid}
            isError={redeemCodeValueIsError}
            message={messageText}
            customClassName={getClassNameForValidation(
              redeemCodeRole,
              redeemCodeValueIsValid && !redeemCodeValueIsError,
              messageText,
              messageAttributes
            )}
          >
            <TextInput
              id="enterAccessCode"
              placeholder={CMS.enter_a_code_placeholder}
              maxLength={14}
              label={CMS.enter_access_code_text}
              labelSub={featureIsEnabled('opt-main-features') ? CMS.enter_access_code_body_text : ''}
              value={redeemCodeValue}
              onChange={value => {
                setVstRedeemCodeAction(value, role);
              }}
              onBlur={() => {
                validateVstRedeemCodeAction(role);
              }}
            />
          </Validation>

          {featureIsEnabled('opt-main-features') && isHubMode() ? null : (
            <>
              <p className="gin-top2">{CMS.enter_access_code_body_text}</p>
              <div className={styles.infoContainer}>
                <div>
                  <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
                </div>
                <p>
                  <span>{CMS.important_text}</span>
                  <span>{CMS.info_message}</span>
                </p>
              </div>
              <br />

              <p>
                {isHubMode() ? (
                  <TextLink to={hubHelpLink} target="_blank" className={styles.link}>
                    {CMS.help_link_text}
                  </TextLink>
                ) : (
                  <TextLink
                    to={APP_CONSTANTS.REDEEM_CODE_HELP_LINK}
                    target="_blank"
                    component={RouterLink}
                    className={styles.link}
                  >
                    {CMS.help_link_text}
                  </TextLink>
                )}
              </p>
              <br />
              <p>
                <TextLink to={APP_CONSTANTS.REDEEM_CODE_PURCHASE_LINK} target="_blank" className={styles.link}>
                  {CMS.purchase_additional_codes_link_text}
                </TextLink>
              </p>
            </>
          )}
        </div>
        {featureIsEnabled('opt-main-features') && isHubMode() ? (
          <>
            <div className={`${styles.infoContainer} ${styles.infoContainerWithMargin}`}>
              <div>
                <SVGIcon glyph={GLYPHS.ICON_INFORMATION_CIRCLE} className={styles.infoIcon} />
              </div>
              <p>
                <span>{CMS.enter_access_code_body_text}</span>
              </p>
            </div>
            <div className={styles.link}>
              <TextLink to={APP_CONSTANTS.HUB_SUPPORT_CONTACT_US} target="_blank">
                {CMS.help_link_text}
              </TextLink>

              <br />
              <br />

              <TextLink to={APP_CONSTANTS.REDEEM_CODE_PURCHASE_LINK} target="_blank" className={styles.link}>
                {CMS.purchase_additional_codes_link_text}
              </TextLink>
            </div>
          </>
        ) : null}
      </ScrollContainer>
    </form>
  );
}

AddToProfileEditInputting.propTypes = {
  closePanel: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  redeemCodeValue: PropTypes.string.isRequired,
  redeemCodeValueIsValid: PropTypes.bool.isRequired,
  redeemCodeValueIsError: PropTypes.bool.isRequired,
  redeemCodeRole: PropTypes.string,
  isVstCode: PropTypes.bool,
  validateVstRedeemCodeAction: PropTypes.func,
  setVstRedeemCodeAction: PropTypes.func,
  errorMessage: PropTypes.object,
  role: PropTypes.string,
  setReCAPTCHATokenAction: PropTypes.func.isRequired,
  googleReCaptchaProps: PropTypes.object.isRequired
};

export default connect(
  state => ({
    file: state.addToProfile.file,
    isFileSelected: state.addToProfile.isFileSelected,
    redeemCodeValue: state.addToProfile.redeemCodeValue,
    redeemCodeRole: state.addToProfile.redeemCodeRole,
    redeemCodeValueIsValid: state.addToProfile.redeemCodeValueIsValid,
    redeemCodeValueIsError: state.addToProfile.redeemCodeValueIsError,
    isVstCode: state.addToProfile.isVstCode,
    errorMessage: state.addToProfile.errorMessage,
    role: state.identity.role
  }),
  {
    submitFormAction: submitForm,
    setAccessCodeAction: setAccessCode,
    validateAccessCodeAction: validateAccessCode,
    validateVstRedeemCodeAction: validateVstActivationCode,
    setVstRedeemCodeAction: setVstActivationCode,
    setReCAPTCHATokenAction: reCAPTCHASetToken
  }
)(withGoogleReCaptcha(AddToProfileEditInputting));
