import { isMarketingPrefEnabledPlatform } from '@oup/shared-node-browser/marketingPrefUtils.js';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import APP_CONSTANTS from '@oup/shared-node-browser/constants';
import Button from '@oup/shared-front-end/src/components/Button';
import pick from 'lodash.pick';
import MarketingPreferencesConsentForm from '../../components/MarketingPreferencesConsentForm/MarketingPreferencesConsentForm';
import UserAgreement from '../../components/UserAgreement/UserAgreement';
import DropdownSearchModal from '../../components/DropdownSearchModal/DropdownSearchModal';
import withLocalizedContent from '../../language/withLocalizedContent';
import { inspJobTitles } from '../../globals/inspConstants';
// Action imports
import {
  formStates,
  setFirstName,
  setLastName,
  setUserName,
  setPassword,
  setEmailConfirmation,
  setTermsAndConditions,
  setMarketPref,
  setSelfSelectedRole,
  setClaimedSchool,
  setCountryCode,
  validateFirstName,
  validateLastName,
  validateUserName,
  validatePassword,
  validateEmailConfirmation,
  validateTermsAndConditions,
  validateSelfSelectedRole,
  validateClaimedSchool,
  validateCountryCode,
  submitForm,
  signIn,
  sendEmailInvitation,
  setUnderAgeAccepted
} from '../../redux/reducers/registration/registration.reducer.js';

// Component imports
import TextInputFederatedAccess from '../../components/TextInputFederatedAccess/TextInputFederatedAccess.js';
import Validation from '../../components/Validation/Validation.js';
import Checkbox from '../../components/Checkbox/Checkbox.js';
import TextInput from '../../components/TextInput/TextInput.js';
import { featureIsEnabled } from '../../globals/envSettings';
import { isCesMode, isEbookSampleMode, isOteMode } from '../../utils/platform';
import cmsContent from '../../utils/cmsContent.js';

import { USER_REGISTRATION_STATUS } from '../../globals/appConstants.js';
import Dropdown from '../../components/Dropdown/Dropdown.js';

import inspContent from '../../../language/glossaries/en/eps-client/inspectionCopies.glossary.json';

// Simple lookups to make code more readable: (Eg: isLegacyUser[formState] --> true/falsey)
const isLegacyUser = {
  [formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY]: true
};
const isExistingUser = {
  [formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING]: true
};

function RegisterFormFederatedAccess({
  localizedContent: { textInputFederatedAccessComponent: content, countryCodes },
  // This is used to adapt the username/email field to FULL/PARTIAL registration:
  formState,

  // Field values
  firstNameValue,
  lastNameValue,
  userNameValue,
  selfSelectedRoleValue,
  claimedSchoolValue,
  countryCodeValue,
  passwordValue,
  emailConfirmationValue,
  termsAndConditionsValue,

  // Field "is valid" flags are used to show a nice green tick net to each field:
  isFirstNameValid,
  isLastNameValid,
  isUserNameValid,
  isSelfSelectedRoleValid,
  isClaimedSchoolValid,
  isCountryCodeValid,
  isPasswordValid,
  isEmailConfirmationValid,
  isTermsAndConditionsValid,

  // Field errors:
  firstNameError,
  lastNameError,
  userNameError,
  selfSelectedRoleError,
  claimedSchoolError,
  countryCodeError,
  passwordError,
  emailConfirmationError,
  termsAndConditionsError,
  registrationStatus,

  // Field onChanges
  setFirstNameAction,
  setLastNameAction,
  setUserNameAction,
  setSelfSelectedRoleAction,
  setClaimedSchoolAction,
  setCountryCodeAction,
  setPasswordAction,
  setTermsAndConditionsAction,
  setEmailConfirmationAction,
  setUnderAgeAcceptedAction,
  setMarketPrefAction,

  // To validate on onBlur
  validateFirstNameAction,
  validateLastNameAction,
  validateUserNameAction,
  validateSelfSelectedRoleAction,
  validateClaimedSchoolAction,
  validateCountryCodeAction,
  validatePasswordAction,
  validateEmailConfirmationAction,

  // To validate on onChange (Checkboxes)
  validateTermsAndConditionsAction,

  submitFormAction,
  sendEmailInvitationAction,
  signInAction,
  platform,
  returnUrl,
  preview = false
}) {
  // Decide whether to label userName field as email instead:
  const userNameOrEmail = formState === formStates.INPUTTING_NEW ? 'userName' : 'email';

  const countries = Object.keys(countryCodes).map(code => ({ text: countryCodes[code], value: code }));

  const CMS = cmsContent.registrationPage || {};

  // Decide whether all mandatory fields have been filled correctly:
  let isFormValid;

  switch (formState) {
    case formStates.INPUTTING_PARTIAL_VIA_FINALISE_EXISTING:
      isFormValid = isTermsAndConditionsValid;
      break;

    case formStates.INPUTTING_PARTIAL_VIA_FINALISE_LEGACY:
      isFormValid = isUserNameValid && isTermsAndConditionsValid;
      break;

    case formStates.INPUTTING_PARTIAL_VIA_TOKEN:
      isFormValid = isLastNameValid && isFirstNameValid && isPasswordValid && isTermsAndConditionsValid;
      break;

    // else:
    // case INPUTTING_NEW:
    default:
      isFormValid =
        isLastNameValid &&
        isFirstNameValid &&
        isUserNameValid &&
        isPasswordValid &&
        isEmailConfirmationValid &&
        isTermsAndConditionsValid &&
        (isEbookSampleMode() ? isSelfSelectedRoleValid && isClaimedSchoolValid && isCountryCodeValid : true);
  }

  let userNameValidationMessage = '';
  let userNameValidationButtonText = '';
  let userNameValidationButtonLink = '';
  let userNameValidationButtonOnClick = null;
  switch (registrationStatus) {
    case USER_REGISTRATION_STATUS.REGISTRATION_PENDING:
      userNameValidationMessage = CMS.username_exists_partial_register_text;
      userNameValidationButtonText = CMS.button_send_mail_text;
      userNameValidationButtonLink = '';
      userNameValidationButtonOnClick = sendEmailInvitationAction;
      break;

    case USER_REGISTRATION_STATUS.REGISTRATION_COMPLETE:
    case USER_REGISTRATION_STATUS.REGISTRATION_EXISTS:
      userNameValidationMessage = CMS.username_exists_sign_in_text;
      userNameValidationButtonText = CMS.button_sign_in_text;
      userNameValidationButtonLink = '';
      userNameValidationButtonOnClick = () => {
        // Handle code redemption to autopopulate when routed from redeem page and password reset
        if (isCesMode() && localStorage.getItem(APP_CONSTANTS.REDEEM_CODE)) {
          sessionStorage.setItem('goto-path', '/myProfile/learningMaterial/addCode');
        }
        signInAction();
      };
      break;

    default:
      userNameValidationMessage = userNameError;
      userNameValidationButtonText = '';
      userNameValidationButtonLink = '';
      break;
  }

  const isExtRegFeature = featureIsEnabled('ote-extend-registration');
  const isOTEPlatform = isOteMode();
  const createButtonLabel =
    isOTEPlatform && isExtRegFeature
      ? CMS.button_registration_submit_create_text_ote
      : CMS.button_registration_submit_create_text;

  const options = inspJobTitles?.options || [];

  return (
    <form>
      {!isLegacyUser[formState] && !isExistingUser[formState] ? (
        <div>
          <Validation
            forId={TextInputFederatedAccess.defaultsFor().firstName.id}
            isValid={isFirstNameValid}
            isError={!!firstNameError}
            message={firstNameError}
          >
            <TextInputFederatedAccess
              {...TextInputFederatedAccess.defaultsFor().firstName}
              value={firstNameValue}
              disableAutoComplete
              onChange={setFirstNameAction}
              onBlur={validateFirstNameAction}
            />
          </Validation>

          <Validation
            forId={TextInputFederatedAccess.defaultsFor().lastName.id}
            isValid={isLastNameValid}
            isError={!!lastNameError}
            message={lastNameError}
          >
            <TextInputFederatedAccess
              {...TextInputFederatedAccess.defaultsFor().lastName}
              value={lastNameValue}
              disableAutoComplete
              onChange={setLastNameAction}
              onBlur={validateLastNameAction}
            />
          </Validation>
        </div>
      ) : null}

      {/* This field displays as either userName or email depending on full or partial registration: */}
      {!isExistingUser[formState] ? (
        <div>
          {formState === formStates.INPUTTING_PARTIAL_VIA_TOKEN ? (
            <Validation forId={TextInput.defaultsFor().userName.id} isValid>
              <TextInputFederatedAccess
                {...TextInputFederatedAccess.defaultsFor()[userNameOrEmail]}
                id={TextInputFederatedAccess.defaultsFor().userName.id}
                value={userNameValue}
                disabled
              />
            </Validation>
          ) : (
            <Validation
              forId={TextInputFederatedAccess.defaultsFor().userName.id}
              isValid={isUserNameValid}
              isError={!!userNameError}
              message={userNameValidationMessage}
              buttonText={userNameValidationButtonText}
              buttonLink={userNameValidationButtonLink}
              buttonOnClick={userNameValidationButtonOnClick}
            >
              <TextInputFederatedAccess
                {...TextInputFederatedAccess.defaultsFor()[userNameOrEmail]}
                id={TextInputFederatedAccess.defaultsFor().userName.id}
                value={userNameValue}
                onChange={setUserNameAction}
                onBlur={userNameError ? null : validateUserNameAction}
              />
            </Validation>
          )}

          {!isLegacyUser[formState] && formState !== formStates.INPUTTING_PARTIAL_VIA_TOKEN && !isEbookSampleMode() ? (
            <p
              className="pad-top1"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: TextInputFederatedAccess.defaultsFor()[userNameOrEmail].tip }}
            />
          ) : null}
          {formState === formStates.INPUTTING_PARTIAL_VIA_TOKEN ? null : (
            <Validation
              forId={TextInputFederatedAccess.defaultsFor().emailConfirm.id}
              isValid={isEmailConfirmationValid}
              isError={!!emailConfirmationError}
              message={emailConfirmationError}
            >
              <TextInputFederatedAccess
                {...TextInputFederatedAccess.defaultsFor().emailConfirm}
                value={emailConfirmationValue}
                onChange={setEmailConfirmationAction}
                onBlur={validateEmailConfirmationAction}
                onPaste={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  const clipboard = e.clipboardData || window.clipboardData;
                  validateEmailConfirmationAction(null, null, clipboard.getData('Text'));
                }}
              />
            </Validation>
          )}
        </div>
      ) : null}
      {isEbookSampleMode() && (
        <div>
          <div>
            <Validation
              forId={TextInputFederatedAccess.defaultsFor().role.id}
              isValid={isSelfSelectedRoleValid}
              isError={!!selfSelectedRoleError}
              message={selfSelectedRoleError}
            >
              <DropdownSearchModal
                {...TextInputFederatedAccess.defaultsFor().role}
                options={options}
                buttonText={inspContent.glossary.dropdown_modal_button_placeholder}
                searchPlaceholder={inspContent.glossary.dropdown_modal_search_placeholder}
                modalTitle={inspContent.glossary.dropdown_modal_search_modal_title}
                disableAutoComplete
                value={selfSelectedRoleValue}
                onSetAction={setSelfSelectedRoleAction}
                onValidationAction={validateSelfSelectedRoleAction}
                required
              />
            </Validation>
          </div>
          <div>
            <Validation
              forId={TextInputFederatedAccess.defaultsFor().school.id}
              isValid={isClaimedSchoolValid}
              isError={!!claimedSchoolError}
              message={claimedSchoolError}
            >
              <TextInputFederatedAccess
                {...TextInputFederatedAccess.defaultsFor().school}
                value={claimedSchoolValue}
                disableAutoComplete
                onChange={setClaimedSchoolAction}
                onBlur={validateClaimedSchoolAction}
                required
              />
            </Validation>
          </div>

          <Validation
            forId="countryCode"
            isActive={!preview ? countryCodeError : null}
            isValid={isCountryCodeValid}
            message={countryCodeError}
            isError={!!countryCodeError}
          >
            <Dropdown
              id="countryCode"
              name="countryCode"
              value={countryCodeValue}
              label={content.country_label}
              options={[{ text: 'Please select', value: '' }, ...countries]}
              onChange={setCountryCodeAction}
              onBlur={validateCountryCodeAction}
              required
            />
          </Validation>
        </div>
      )}

      {!isLegacyUser[formState] && !isExistingUser[formState] ? (
        <div>
          <Validation
            forId={TextInputFederatedAccess.defaultsFor().password.id}
            isValid={isPasswordValid}
            isError={!!passwordError}
            message={passwordError}
          >
            <TextInputFederatedAccess
              {...TextInputFederatedAccess.defaultsFor().password}
              value={passwordValue}
              onChange={setPasswordAction}
              onBlur={validatePasswordAction}
            />
          </Validation>

          <p className="pad-top1">{TextInputFederatedAccess.defaultsFor().password.tip}</p>
        </div>
      ) : null}

      {featureIsEnabled('enable-marketing-preferences') &&
      isMarketingPrefEnabledPlatform(platform) &&
      (!returnUrl || (returnUrl && !returnUrl.includes('oxfordreadingbuddy'))) ? (
        <>
          <div className="pad-top2">
            <MarketingPreferencesConsentForm
              platform={platform}
              setMarketTermsAndConditions={value => {
                setTermsAndConditionsAction(value);
              }}
              setUnderAgeAccepted={value => {
                setUnderAgeAcceptedAction(value);
              }}
              setMarketPreference={value => {
                setMarketPrefAction(value);
              }}
            />
          </div>
          <div className="pad-top2">
            <UserAgreement personalInformation />
          </div>
        </>
      ) : (
        <>
          <h4 className="pad-top2x">
            <em>{TextInput.defaultsFor().termsAndConditions.groupLabel}</em>
          </h4>
          <Validation
            forId={TextInput.defaultsFor().termsAndConditions.id}
            isValid={isTermsAndConditionsValid}
            isError={!!termsAndConditionsError}
            message={termsAndConditionsError}
            noLabelAboveField
          >
            <Checkbox
              {...TextInput.defaultsFor().termsAndConditions}
              value={termsAndConditionsValue}
              required
              onChange={e => {
                setTermsAndConditionsAction(e);
                validateTermsAndConditionsAction(e);
              }}
            />
          </Validation>
          <div className="pad-top2">
            <UserAgreement dataUsage privacyPolicy />
          </div>
        </>
      )}

      <div className="pad-top2">
        <input type="hidden" value={platform} name="platformCode" />

        <Button
          id="registration-submit"
          variant="filled"
          text={
            isLegacyUser[formState] || isExistingUser[formState]
              ? CMS.button_registration_submit_update_text
              : createButtonLabel
          }
          onClick={submitFormAction}
          disabled={!isFormValid}
        />
      </div>
    </form>
  );
}

RegisterFormFederatedAccess.propTypes = {
  localizedContent: PropTypes.object.isRequired,
  formState: PropTypes.oneOf(Object.values(formStates)).isRequired,

  // Field values
  firstNameValue: PropTypes.string.isRequired,
  lastNameValue: PropTypes.string.isRequired,
  userNameValue: PropTypes.string.isRequired,
  passwordValue: PropTypes.string.isRequired,
  emailConfirmationValue: PropTypes.string.isRequired,
  termsAndConditionsValue: PropTypes.bool.isRequired,
  selfSelectedRoleValue: PropTypes.string.isRequired,
  claimedSchoolValue: PropTypes.string.isRequired,
  countryCodeValue: PropTypes.string.isRequired,

  // Field "is valid" flags:
  isFirstNameValid: PropTypes.bool.isRequired,
  isLastNameValid: PropTypes.bool.isRequired,
  isUserNameValid: PropTypes.bool.isRequired,
  isPasswordValid: PropTypes.bool.isRequired,
  isEmailConfirmationValid: PropTypes.bool.isRequired,
  isTermsAndConditionsValid: PropTypes.bool.isRequired,
  isSelfSelectedRoleValid: PropTypes.bool.isRequired,
  isClaimedSchoolValid: PropTypes.bool.isRequired,
  isCountryCodeValid: PropTypes.bool.isRequired,

  // Field errors
  firstNameError: PropTypes.string,
  lastNameError: PropTypes.string,
  userNameError: PropTypes.string,
  passwordError: PropTypes.string,
  emailConfirmationError: PropTypes.string,
  termsAndConditionsError: PropTypes.string,
  selfSelectedRoleError: PropTypes.string,
  claimedSchoolError: PropTypes.string,
  countryCodeError: PropTypes.string,

  registrationStatus: PropTypes.string,

  // Field onChanges
  setFirstNameAction: PropTypes.func.isRequired,
  setLastNameAction: PropTypes.func.isRequired,
  setUserNameAction: PropTypes.func.isRequired,
  setPasswordAction: PropTypes.func.isRequired,
  setEmailConfirmationAction: PropTypes.func.isRequired,
  setTermsAndConditionsAction: PropTypes.func.isRequired,
  setUnderAgeAcceptedAction: PropTypes.func.isRequired,
  setMarketPrefAction: PropTypes.func,
  setSelfSelectedRoleAction: PropTypes.func.isRequired,
  setClaimedSchoolAction: PropTypes.func.isRequired,
  setCountryCodeAction: PropTypes.func.isRequired,
  submitFormAction: PropTypes.func.isRequired,
  sendEmailInvitationAction: PropTypes.func.isRequired,
  signInAction: PropTypes.func.isRequired,

  // To validate on onBlur
  validateFirstNameAction: PropTypes.func.isRequired,
  validateLastNameAction: PropTypes.func.isRequired,
  validateUserNameAction: PropTypes.func.isRequired,
  validatePasswordAction: PropTypes.func.isRequired,
  validateEmailConfirmationAction: PropTypes.func.isRequired,
  validateTermsAndConditionsAction: PropTypes.func.isRequired,
  validateSelfSelectedRoleAction: PropTypes.func.isRequired,
  validateClaimedSchoolAction: PropTypes.func.isRequired,
  validateCountryCodeAction: PropTypes.func.isRequired,
  platform: PropTypes.string,
  returnUrl: PropTypes.string,
  countryCodes: PropTypes.object,
  preview: PropTypes.bool,
  errors: PropTypes.object,
  textInputFederatedAccess: PropTypes.object
};

export default compose(
  withLocalizedContent('textInputFederatedAccessComponent', 'countryCodes'),
  connect(
    state => ({
      ...pick(state.registration, ['countryCode']),
      // Connect values
      firstNameValue: state.registration.firstNameValue,
      lastNameValue: state.registration.lastNameValue,
      userNameValue: state.registration.userNameValue,
      passwordValue: state.registration.passwordValue,
      emailConfirmationValue: state.registration.emailConfirmationValue,
      termsAndConditionsValue: state.registration.termsAndConditionsValue,
      underAgeAcceptedValue: state.registration.underAgeAcceptedValue,
      marketingEmailAcceptedValue: state.registration.marketingEmailAcceptedValue,
      selfSelectedRoleValue: state.registration.selfSelectedRoleValue,
      claimedSchoolValue: state.registration.claimedSchoolValue,
      countryCodeValue: state.registration.countryCodeValue,

      isFirstNameValid: state.registration.isFirstNameValid,
      isLastNameValid: state.registration.isLastNameValid,
      isUserNameValid: state.registration.isUserNameValid,
      isPasswordValid: state.registration.isPasswordValid,
      isEmailConfirmationValid: state.registration.isEmailConfirmationValid,
      isTermsAndConditionsValid: state.registration.isTermsAndConditionsValid,
      isSelfSelectedRoleValid: state.registration.isSelfSelectedRoleValid,
      isClaimedSchoolValid: state.registration.isClaimedSchoolValid,
      isCountryCodeValid: state.registration.isCountryCodeValid,

      // Connect errors
      firstNameError: state.registration.firstNameError,
      lastNameError: state.registration.lastNameError,
      userNameError: state.registration.userNameError,
      passwordError: state.registration.passwordError,
      emailConfirmationError: state.registration.emailConfirmationError,
      termsAndConditionsError: state.registration.termsAndConditionsError,
      selfSelectedRoleError: state.registration.selfSelectedRoleError,
      claimedSchoolError: state.registration.claimedSchoolError,
      countryCodeError: state.registration.countryCodeError,

      registrationStatus: state.registration.registrationStatus
    }),
    {
      // Connect onChange actions
      setFirstNameAction: setFirstName,
      setLastNameAction: setLastName,
      setUserNameAction: setUserName,
      setPasswordAction: setPassword,
      setEmailConfirmationAction: setEmailConfirmation,
      setTermsAndConditionsAction: setTermsAndConditions,
      setMarketPrefAction: setMarketPref,
      setUnderAgeAcceptedAction: setUnderAgeAccepted,
      setSelfSelectedRoleAction: setSelfSelectedRole,
      setClaimedSchoolAction: setClaimedSchool,
      setCountryCodeAction: setCountryCode,

      // Connect onBlur actions
      validateFirstNameAction: validateFirstName,
      validateLastNameAction: validateLastName,
      validateUserNameAction: validateUserName,
      validatePasswordAction: validatePassword,
      validateEmailConfirmationAction: validateEmailConfirmation,
      validateTermsAndConditionsAction: validateTermsAndConditions,
      validateSelfSelectedRoleAction: validateSelfSelectedRole,
      validateClaimedSchoolAction: validateClaimedSchool,
      validateCountryCodeAction: validateCountryCode,

      submitFormAction: submitForm,
      sendEmailInvitationAction: sendEmailInvitation,
      signInAction: signIn
    }
  )
)(RegisterFormFederatedAccess);
