import jsonFetch from '../util/jsonFetch';

export default async payload => {
  const requestPayload = {
    orgId: payload.orgId,
    productId: payload.productId,
    clientId: payload.clientId,
    lmsCourseId: payload.lmsCourseId
  };

  const headers = { 'Content-Type': 'application/json' };
  const body = JSON.stringify(requestPayload);

  const url = `${__API_BASE__}/open/ltitool1v3/content-selection-confirmation`;

  const response = await jsonFetch('processLineItemsBatch', [url, { method: 'PUT', headers, body }], false);

  return response;
};
