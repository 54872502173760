import * as t from '../../actionTypes.js';

const initialState = {
  loading: false,
  remaining: null
};

export default function processLineItemsBatches(state = initialState, action) {
  switch (action.type) {
    case t.NGI_STORE_LINE_ITEMS_REQUEST:
      return { ...state, loading: true, error: null };
    case t.NGI_STORE_LINE_ITEMS_SUCCESS:
      return { ...state, loading: action.payload.remaining > 0, error: null };
    case t.NGI_STORE_LINE_ITEMS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}
