import { call, put, takeLatest } from 'redux-saga/effects';
import * as t from '../../../../actionTypes';

import { storeLineItemsFailure } from '../../../../actions/storeLineItemsActions.js';
import processLineItemsBatch from '../../../apiCalls/ngi/processLineItemsBatch.js';

function* processLineItems(action) {
  const requestBody = {
    orgId: action.payload.lineItemsConfig.orgId,
    clientId: action.payload.lineItemsConfig.clientId,
    lmsCourseId: action.payload.lineItemsConfig.lmsCourseId
  };

  const selectedProducts = action.payload.selectedProducts;

  let response;
  try {
    const confirmedProductsWithGradeSync = action.payload.confirmedProducts.filter(product => product.addGradeItems);
    for (let i = 0; i < confirmedProductsWithGradeSync.length; i += 1) {
      do {
        const productId = selectedProducts.find(
          product => product.isbn === confirmedProductsWithGradeSync[i].isbnLicense
        )?.productid;
        response = yield call(processLineItemsBatch, { ...requestBody, productId });
        console.log(response);
      } while (response.remaining !== 0);
    }

    action.callback();
  } catch (error) {
    yield put(storeLineItemsFailure('Failed to store line items'));
  }
}

function* processLineItemsSaga() {
  yield takeLatest(t.NGI_STORE_LINE_ITEMS_REQUEST, processLineItems);
}

export default processLineItemsSaga;
